import {forwardRef, React, useImperativeHandle, useState} from "react";
import {useDispatch} from "react-redux";
// import jQuery from "jquery";
import {appointmentListsAction} from "../../actions/appointmentActions";
import {customerListsAction} from "../../actions/customerActions";
import {
  cleanerListsAction,
  cleanerReviewListsAction
} from "../../actions/cleanerActions";
import {driverListsAction} from "../../actions/driverActions";
import {partnerListsAction} from "../../actions/partnerActions";
import {
  reportListsAction,
  reportReportDownloadAction
} from "../../actions/reportActions";
import {useSearchParams} from "react-router-dom";

const CommonFilter = forwardRef(
  (
    {
      listName,
      customer,
      setCustomer,
      gender,
      setGender,
      cleaner,
      setCleaner,
      driver,
      setDriver,
      partner,
      setPartner,
      frequency,
      setFrequency,
      country,
      setCountry,
      paymentMethod,
      setPaymentMethod,
      startDate,
      setStartDate,
      createdAt,
      setCreatedAt,
      paymentStatus,
      setPaymentStatus,
      appointmentStatus,
      setAppointmentStatus,
      appRef,
      setAppRef,
      offset,
      sortBy,
      sortOrder,
      id,
      page,
      setSearchParams,
      countries,
      frequencys,
      dutyStatus,
      setDutyStatus,
      isPartner,
      rhMember,
      setRhMember,
      dob,
      setDob,
      showFilter,
      pd,
      listUrl,
      secondaryUrl,
      downloadUrl,
      pc,
      quickVal
    },
    ref
  ) => {
    var download = false;
    let sheetName = "";
    useImperativeHandle(ref, () => ({
      async log(
        listUrlSort,
        secondaryUrlSort,
        downloadUrlSort,
        sheetNameSort = "report",
        downloadSort = false
      ) {
        sheetName = sheetNameSort;
        download = downloadSort;
        await handleAppointmentFilter();
      },
      async queryFilterMaking(listUrl, secondaryUrl) {
        return await filterQueryUrl(listUrl, secondaryUrl);
      }
    }));

    const query = new URLSearchParams();
    const [searchQuery, setSearchQuery] = useSearchParams();
    // const baseUrl = process.env.REACT_APP_LOCAL_API_URL

    const paymentModeArray = [
      {key: "Cash", pair: "Cash"},
      {key: "Bank Transfer", pair: "Bank Transfer"},
      {key: "Cheque", pair: "Cheque"},
      {key: "Gateway", pair: "Gateway"},
      {key: "Other", pair: "Other"},
      {key: "cod", pair: "COD"},
      {key: "card", pair: "Card"},
      {key: "applepay", pair: "Apple Pay"},
      {key: "online", pair: "Online"}
    ];

    const dispatch = useDispatch();

    let queryUrl;
    const switchStatement = async (
      list,
      filter = false,
      isClicked = false,
      isReset = false
    ) => {
      switch (list) {
        case "customer": {
          // setListUrl(baseUrl + "/admin/customer/list/")
          // setSecondaryUrl(baseUrl + `/admin/customer/list/?page=${page}`)
          if (download) {
            queryUrl = await filterQueryUrl(
              downloadUrl,
              downloadUrl,
              filter,
              true
            );
            dispatch(reportReportDownloadAction(queryUrl, "Customers"));
          } else {
            queryUrl = await filterQueryUrl(
              listUrl,
              secondaryUrl,
              filter,
              isClicked
            );
            if (isReset) dispatch(customerListsAction(listUrl));
            else dispatch(customerListsAction(queryUrl));
          }
          break;
        }
        case "cleaner": {
          // setListUrl(baseUrl + "/cleaner/list/")
          // setSecondaryUrl(baseUrl + `/cleaner/list/?page=${page}`)

          if (download) {
            queryUrl = await filterQueryUrl(
              downloadUrl,
              downloadUrl,
              filter,
              true
            );
            dispatch(reportReportDownloadAction(queryUrl, "Pros"));
          } else {
            queryUrl = await filterQueryUrl(
              listUrl,
              secondaryUrl,
              filter,
              isClicked
            );
            if (isReset) dispatch(cleanerListsAction(listUrl, isPartner));
            else dispatch(cleanerListsAction(queryUrl, isPartner));
          }
          break;
        }
        case "driver": {
          // setListUrl(baseUrl + "/driver/list/")
          // setSecondaryUrl(baseUrl + `/driver/list/?page=${page}`)

          if (download) {
            queryUrl = await filterQueryUrl(
              downloadUrl,
              downloadUrl,
              filter,
              true
            );
            dispatch(reportReportDownloadAction(queryUrl, "Drivers"));
          } else {
            queryUrl = await filterQueryUrl(
              listUrl,
              secondaryUrl,
              filter,
              isClicked
            );
            if (isReset) dispatch(driverListsAction(listUrl, isPartner));
            else dispatch(driverListsAction(queryUrl, isPartner));
          }
          break;
        }
        case "partner": {
          // setListUrl(baseUrl + "/partner/list/")
          // setSecondaryUrl(baseUrl + `/partner/list/?page=${page}`)

          if (download) {
            queryUrl = await filterQueryUrl(
              downloadUrl,
              downloadUrl,
              filter,
              true
            );
            dispatch(reportReportDownloadAction(queryUrl, "Partners"));
          } else {
            queryUrl = await filterQueryUrl(
              listUrl,
              secondaryUrl,
              filter,
              isClicked
            );
            if (isReset) dispatch(partnerListsAction(listUrl));
            else dispatch(partnerListsAction(queryUrl));
          }
          break;
        }
        case "partnerPayment": {
          // setListUrl(baseUrl + "/partner/payment/list/" + id)
          // setSecondaryUrl(baseUrl + `/partner/payment/list/${id}?page=${page}`)

          if (download) {
            queryUrl = await filterQueryUrl(
              downloadUrl,
              downloadUrl,
              filter,
              true
            );
            dispatch(reportReportDownloadAction(queryUrl, "PartnerPayment"));
          } else {
            queryUrl = await filterQueryUrl(
              listUrl,
              secondaryUrl,
              filter,
              isClicked
            );
            if (isReset) dispatch(partnerListsAction(listUrl));
            else dispatch(partnerListsAction(queryUrl));
          }
          break;
        }
        case "review": {
          // setListUrl(baseUrl + "/cleaner/admin/review/list/")
          // setSecondaryUrl(baseUrl + `/cleaner/admin/review/list/?page=${page}`)

          if (download) {
            queryUrl = await filterQueryUrl(
              downloadUrl,
              downloadUrl,
              filter,
              true
            );
            dispatch(reportReportDownloadAction(queryUrl, "ProReview"));
          } else {
            queryUrl = await filterQueryUrl(
              listUrl,
              secondaryUrl,
              filter,
              isClicked
            );
            if (isReset) dispatch(cleanerReviewListsAction(listUrl));
            else dispatch(cleanerReviewListsAction(queryUrl));
          }
          break;
        }
        case "driverPerformance": {
          // setListUrl(baseUrl + "/report/driver/performance/")
          // setSecondaryUrl(baseUrl + `/report/driver/performance/?page=${page}`)

          if (download) {
            queryUrl = await filterQueryUrl(downloadUrl, downloadUrl, filter);
            dispatch(reportReportDownloadAction(queryUrl, "DriverPerformance"));
          } else {
            queryUrl = await filterQueryUrl(
              listUrl,
              secondaryUrl,
              filter,
              isClicked
            );
            if (isReset) dispatch(reportListsAction(listUrl));
            else dispatch(reportListsAction(queryUrl));
          }
          break;
        }
        case "cleanerPerformance": {
          // setListUrl(baseUrl + "/report/pro/performance/")
          // setSecondaryUrl(baseUrl + `/report/pro/performance/?page=${page}`)
          if (download) {
            queryUrl = await filterQueryUrl(downloadUrl, downloadUrl, filter);
            dispatch(
              reportReportDownloadAction(queryUrl, "CleanerPerformance")
            );
          } else {
            queryUrl = await filterQueryUrl(
              listUrl,
              secondaryUrl,
              filter,
              isClicked
            );
            if (isReset) dispatch(reportListsAction(listUrl));
            else dispatch(reportListsAction(queryUrl));
          }
          break;
        }
        case "rightHandRevenue": {
          //setListUrl(baseUrl + "/report/pro/performance/")
          // setSecondaryUrl(baseUrl + `/report/pro/performance/?page=${page}`)
          if (download) {
            queryUrl = await filterQueryUrl(downloadUrl, downloadUrl, filter);
            dispatch(reportReportDownloadAction(queryUrl, "rightHandRevenue"));
          } else {
            queryUrl = await filterQueryUrl(
              listUrl,
              secondaryUrl,
              filter,
              isClicked
            );
            if (isReset) dispatch(reportListsAction(listUrl));
            else dispatch(reportListsAction(queryUrl));
          }
          break;
        }
        case "reportName": {
          //setListUrl(baseUrl + "/report/pro/performance/")
          // setSecondaryUrl(baseUrl + `/report/pro/performance/?page=${page}`)
          if (download) {
            queryUrl = await filterQueryUrl(downloadUrl, downloadUrl);
            dispatch(reportReportDownloadAction(queryUrl, sheetName));
          } else {
            queryUrl = await filterQueryUrl(
              listUrl,
              secondaryUrl,
              filter,
              isClicked
            );
            if (isReset) dispatch(reportListsAction(listUrl));
            else dispatch(reportListsAction(queryUrl));
          }
          break;
        }
        default: {
          if (download) {
            queryUrl = await filterQueryUrl(downloadUrl, downloadUrl, filter);
            dispatch(reportReportDownloadAction(queryUrl, "Appointments"));
          } else {
            queryUrl = await filterQueryUrl(
              listUrl,
              secondaryUrl,
              filter,
              isClicked
            );
            if (isReset) dispatch(appointmentListsAction(listUrl));
            else dispatch(appointmentListsAction(queryUrl));
          }
          break;
        }
      }
    };

    const filterQueryUrl = async (
      listUrl,
      secondaryUrl,
      filter = false,
      isClicked = false
    ) => {
      let searchParams = {};
      searchParams["createdAt"] = createdAt;
      searchParams["offset"] = offset;
      searchParams["sortBy"] = sortBy;
      searchParams["sortOrder"] = sortOrder;
      searchParams["id"] = id;
      searchParams["showFilter"] = showFilter;
      searchParams["page"] = isClicked ? 1 : page;
      searchParams["showAdvanceFilter"] = showAdvanceFilter;

      searchParams["quickVal"] = quickVal;
      // console.log(searchParams, page)
      // console.log(document.getElementById('inputSearch'))
      if (document.getElementById("inputSearch")) {
        document.getElementById("inputSearch").value = "";
      }
      const appReferenceExist = document.getElementById("appReference");
      if (appReferenceExist) {
        appReferenceExist.value =
          appReferenceExist.value || searchQuery?.get("appReferenceValue");
        var appReferenceValue = appReferenceExist.value;
      }
      const appStatusValueExist = document.getElementById("appointment_status");
      if (appStatusValueExist) {
        var appStatusValue;
        if (isClicked) {
          appStatusValue = appStatusValueExist.value;
        } else {
          appStatusValue = searchQuery?.get("appStatusValue");
          appStatusValueExist.value = searchQuery?.get("appStatusValue");
        }
      }
      const appointmentAdvancedValueExist = document.getElementById(
        "appointmentAdvancedValue"
      );
      if (appointmentAdvancedValueExist) {
        var appointmentAdvancedValue;
        if (isClicked) {
          appointmentAdvancedValue = appointmentAdvancedValueExist.value;
        } else {
          appointmentAdvancedValue = searchQuery?.get(
            "appointmentAdvancedValue"
          );
          appointmentAdvancedValueExist.value = searchQuery?.get(
            "appointmentAdvancedValue"
          );
        }
      }
      const dutyStatusValueExist = document.getElementById("duty_status");
      if (dutyStatusValueExist) {
        var dutyStatusValue;
        if (isClicked) {
          dutyStatusValue = dutyStatusValueExist.value;
        } else {
          dutyStatusValue = searchQuery?.get("dutyStatusValue");
          dutyStatusValueExist.value = searchQuery?.get("dutyStatusValue");
        }
      }
      const dutyAdvancedValueExist =
        document.getElementById("dutyAdvancedValue");
      if (dutyAdvancedValueExist) {
        var dutyAdvancedValue;
        if (isClicked) {
          dutyAdvancedValue = dutyAdvancedValueExist.value;
        } else {
          dutyAdvancedValue = searchQuery?.get("dutyAdvancedValue");
          dutyAdvancedValueExist.value = searchQuery?.get("dutyAdvancedValue");
        }
      }
      const payStatusValueExist = document.getElementById("payment_status");
      if (payStatusValueExist) {
        var payStatusValue;
        if (isClicked) {
          payStatusValue = payStatusValueExist.value;
        } else {
          payStatusValue = searchQuery?.get("payStatusValue");
          payStatusValueExist.value = searchQuery?.get("payStatusValue");
        }
      }
      const payMethodValueExist = document.getElementById("payment_mode");
      if (payMethodValueExist) {
        var payMethodValue;
        if (isClicked) {
          payMethodValue = payMethodValueExist.value;
        } else {
          payMethodValue = searchQuery?.get("payMethodValue");
          payMethodValueExist.value = searchQuery?.get("payMethodValue");
        }
      }
      const paymentAdvancedValueExist = document.getElementById(
        "paymentAdvancedValue"
      );
      if (paymentAdvancedValueExist) {
        var paymentAdvancedValue;
        if (isClicked) {
          paymentAdvancedValue = paymentAdvancedValueExist.value;
        } else {
          paymentAdvancedValue = searchQuery?.get("paymentAdvancedValue");
          paymentAdvancedValueExist.value = searchQuery?.get(
            "paymentAdvancedValue"
          );
        }
      }

      const cleanerValueExist = document.getElementById("cleanerValue");
      if (cleanerValueExist) {
        cleanerValueExist.value =
          cleanerValueExist.value || searchQuery?.get("cleanerValue");
        var cleanerValue = cleanerValueExist.value;
      }
      const driverValueExist = document.getElementById("driverValue");
      if (driverValueExist) {
        driverValueExist.value =
          driverValueExist.value || searchQuery?.get("driverValue");
        var driverValue = driverValueExist.value;
      }
      const customerValueExist = document.getElementById("customerValue");
      if (customerValueExist) {
        customerValueExist.value =
          customerValueExist.value || searchQuery?.get("customerValue");
        var customerValue = customerValueExist.value;
      }
      const partnerValueExist = document.getElementById("partnerValue");
      if (partnerValueExist) {
        partnerValueExist.value =
          partnerValueExist.value || searchQuery?.get("partnerValue");
        var partnerValue = partnerValueExist.value;
      }

      const countryValueExist = document.getElementById("country_value");
      if (countryValueExist) {
        var countryValue;
        if (isClicked) {
          countryValue = countryValueExist.value;
        } else {
          countryValue = searchQuery?.get("countryValue");
          countryValueExist.value = searchQuery?.get("countryValue");
        }
      }
      const countryAdvancedValueExist = document.getElementById(
        "countryAdvancedValue"
      );
      if (countryAdvancedValueExist) {
        var countryAdvancedValue;
        if (isClicked) {
          countryAdvancedValue = countryAdvancedValueExist.value;
        } else {
          countryAdvancedValue = searchQuery?.get("countryAdvancedValue");
          countryAdvancedValueExist.value = searchQuery?.get(
            "countryAdvancedValue"
          );
        }
      }

      const frequencyValueExist = document.getElementById("frequency_value");
      if (frequencyValueExist) {
        var frequencyValue;
        if (isClicked) {
          frequencyValue = frequencyValueExist.value;
        } else {
          frequencyValue = searchQuery?.get("frequencyValue");
          frequencyValueExist.value = searchQuery?.get("frequencyValue");
        }
      }
      const frequencyAdvancedValueExist = document.getElementById(
        "frequencyAdvancedValue"
      );
      if (frequencyAdvancedValueExist) {
        var frequencyAdvancedValue;
        if (isClicked) {
          frequencyAdvancedValue = frequencyAdvancedValueExist.value;
        } else {
          frequencyAdvancedValue = searchQuery?.get("frequencyAdvancedValue");
          frequencyAdvancedValueExist.value = searchQuery?.get(
            "frequencyAdvancedValue"
          );
        }
      }

      const startDateValueExist = document.getElementById("start_date_value");
      if (startDateValueExist) {
        startDateValueExist.value =
          startDateValueExist.value || searchQuery?.get("startDateValue");
        var startDateValue = startDateValueExist.value;
      }
      const endDateValueExist = document.getElementById("end_date_value");
      if (endDateValueExist) {
        endDateValueExist.value =
          endDateValueExist.value || searchQuery?.get("endDateValue");
        var endDateValue = endDateValueExist.value;
      }
      const startDateAdvancedValueExist = document.getElementById(
        "startDateAdvancedValue"
      );
      if (startDateAdvancedValueExist) {
        var startDateAdvancedValue;
        if (isClicked) {
          startDateAdvancedValue = startDateAdvancedValueExist.value;
        } else {
          startDateAdvancedValue = searchQuery?.get("startDateAdvancedValue");
          startDateAdvancedValueExist.value = searchQuery?.get(
            "startDateAdvancedValue"
          );
        }
      }

      const createdAtStartValueExist =
        document.getElementById("created_at_start");
      if (createdAtStartValueExist) {
        createdAtStartValueExist.value =
          createdAtStartValueExist.value ||
          searchQuery?.get("createdAtStartValue");
        var createdAtStartValue = createdAtStartValueExist.value;
        searchParams["quickVal"] = "";
      }
      const createdAtEndValueExist = document.getElementById("created_at_end");
      if (createdAtEndValueExist) {
        createdAtEndValueExist.value =
          createdAtEndValueExist.value || searchQuery?.get("createdAtEndValue");
        var createdAtEndValue = createdAtEndValueExist.value;
      }
      const createdAtAdvancedValueExist = document.getElementById(
        "createdAtAdvancedValue"
      );
      if (createdAtAdvancedValueExist) {
        var createdAtAdvancedValue;
        if (isClicked) {
          createdAtAdvancedValue = createdAtAdvancedValueExist.value;
        } else {
          createdAtAdvancedValue = searchQuery?.get("createdAtAdvancedValue");
          createdAtAdvancedValueExist.value = searchQuery?.get(
            "createdAtAdvancedValue"
          );
        }
      }

      const genderValueExist = document.getElementById("genderValue");
      if (genderValueExist) {
        var genderValue;
        if (isClicked) {
          genderValue = genderValueExist.value;
        } else {
          genderValue = searchQuery?.get("genderValue");
          genderValueExist.value = searchQuery?.get("genderValue");
        }
      }
      const genderAdvancedValueExist = document.getElementById(
        "genderAdvancedValue"
      );
      if (genderAdvancedValueExist) {
        var genderAdvancedValue;
        if (isClicked) {
          genderAdvancedValue = genderAdvancedValueExist.value;
        } else {
          genderAdvancedValue = searchQuery?.get("genderAdvancedValue");
          genderAdvancedValueExist.value = searchQuery?.get(
            "genderAdvancedValue"
          );
        }
      }
      //advanced values

      const dobStartValueExist = document.getElementById("dob_start");
      if (dobStartValueExist) {
        dobStartValueExist.value =
          dobStartValueExist.value || searchQuery?.get("dobStartValue");
        var dobStartValue = dobStartValueExist.value;
      }

      const dobEndValueExist = document.getElementById("dob_end");
      if (dobEndValueExist) {
        dobEndValueExist.value =
          dobEndValueExist.value || searchQuery?.get("dobEndValue");
        var dobEndValue = dobEndValueExist.value;
      }

      const dobAdvancedValueExist = document.getElementById("dobAdvancedValue");
      if (dobAdvancedValueExist) {
        var dobAdvancedValue;
        if (isClicked) {
          dobAdvancedValue = dobAdvancedValueExist.value;
        } else {
          dobAdvancedValue = searchQuery?.get("dobAdvancedValue");
          dobAdvancedValueExist.value = searchQuery?.get("dobAdvancedValue");
        }
      }

      const rhMemberValueExist = document.getElementById("rh_member");
      if (rhMemberValueExist) {
        var rhMemberValue;
        if (isClicked) {
          rhMemberValue = rhMemberValueExist.value;
        } else {
          rhMemberValue = searchQuery?.get("rhMemberValue");
          rhMemberValueExist.value =
            searchQuery?.get("rhMemberValue") === "true";
        }
      }

      if (dutyStatusValue !== "" && dutyStatusValue !== undefined) {
        searchParams["dutyStatus"] = dutyStatus;
        query.set("dutyStatus", dutyStatus);
        searchParams["dutyStatusValue"] = dutyStatusValue;
        query.set("dutyStatusValue", dutyStatusValue);
        if (
          dutyStatusValue !== "app" &&
          dutyAdvancedValue !== "" &&
          dutyAdvancedValue !== undefined
        ) {
          searchParams["dutyAdvancedValue"] = dutyAdvancedValue;
          query.set("dutyAdvancedValue", dutyAdvancedValue);
        }
      }
      if (rhMember && rhMemberValue !== "" && rhMemberValue !== undefined) {
        searchParams["rhMember"] = rhMember;
        query.set("rhMember", rhMember);
        searchParams["rhMemberValue"] = rhMemberValue;
        query.set("rhMemberValue", rhMemberValue);
      }
      if (dob) {
        searchParams["dob"] = dob;
        query.set("dob", dob);
        if (dobStartValue !== "" && dobStartValue !== undefined) {
          searchParams["dobStartValue"] = dobStartValue;
          query.set("dobStartValue", dobStartValue);
        }
        if (dobEndValue !== "" && dobEndValue !== undefined) {
          searchParams["dobEndValue"] = dobEndValue;
          query.set("dobEndValue", dobEndValue);
        }
        if (dobAdvancedValue !== undefined && dobAdvancedValue !== "") {
          searchParams["dobAdvancedValue"] = dobAdvancedValue;
          query.set("dobAdvancedValue", dobAdvancedValue);
        }
      }
      if (createdAt) {
        searchParams["createdAt"] = createdAt;
        query.set("createdAt", createdAt);
        if (createdAtStartValue !== "" && createdAtStartValue !== undefined) {
          searchParams["createdAtStartValue"] = createdAtStartValue;
          query.set("createdAtStartValue", createdAtStartValue);
        }
        if (createdAtEndValue !== "" && createdAtEndValue !== undefined) {
          searchParams["createdAtEndValue"] = createdAtEndValue;
          query.set("createdAtEndValue", createdAtEndValue);
        }
        if (
          createdAtAdvancedValue !== undefined &&
          createdAtAdvancedValue !== ""
        ) {
          searchParams["createdAtAdvancedValue"] = createdAtAdvancedValue;
          query.set("createdAtAdvancedValue", createdAtAdvancedValue);
        }
      }
      if (startDate) {
        searchParams["startDate"] = startDate;
        query.set("startDate", startDate);
        if (startDateValue !== "" && startDateValue !== undefined) {
          searchParams["startDateValue"] = startDateValue;
          query.set("startDateValue", startDateValue);
        }
        if (endDateValue !== "" && endDateValue !== undefined) {
          searchParams["endDateValue"] = endDateValue;
          query.set("endDateValue", endDateValue);
        }
        if (
          startDateAdvancedValue !== undefined &&
          startDateAdvancedValue !== ""
        ) {
          searchParams["startDateAdvancedValue"] = startDateAdvancedValue;
          query.set("startDateAdvancedValue", startDateAdvancedValue);
        }
      }
      if (frequencyValue !== "" && frequencyValue !== undefined) {
        searchParams["frequency"] = frequency;
        query.set("frequency", frequency);
        searchParams["frequencyValue"] = frequencyValue;
        query.set("frequencyValue", frequencyValue);
      }
      if (countryValue !== "" && countryValue !== undefined) {
        searchParams["country"] = country;
        query.set("country", country);
        searchParams["countryValue"] = countryValue;
        query.set("countryValue", countryValue);
        if (countryValue !== "all" && countryAdvancedValue !== undefined) {
          searchParams["countryAdvancedValue"] = countryAdvancedValue;
          query.set("countryAdvancedValue", countryAdvancedValue);
        }
      }
      if (frequencyValue !== "" && frequencyValue !== undefined) {
        searchParams["frequency"] = frequency;
        query.set("frequency", frequency);
        searchParams["frequencyValue"] = frequencyValue;
        query.set("frequencyValue", frequencyValue);
        if (frequencyValue !== "all" && frequencyAdvancedValue !== undefined) {
          searchParams["frequencyAdvancedValue"] = frequencyAdvancedValue;
          query.set("frequencyAdvancedValue", frequencyAdvancedValue);
        }
      }
      if (cleanerValue !== "" && cleanerValue !== undefined) {
        searchParams["cleaner"] = cleaner;
        query.set("cleaner", cleaner);
        searchParams["cleanerValue"] = cleanerValue;
        query.set("cleanerValue", cleanerValue);
      }
      if (driverValue !== "" && driverValue !== undefined) {
        searchParams["driver"] = driver;
        query.set("driver", driver);
        searchParams["driverValue"] = driverValue;
        query.set("driverValue", driverValue);
      }
      if (customerValue !== "" && customerValue !== undefined) {
        searchParams["customer"] = customer;
        query.set("customer", customer);
        searchParams["customerValue"] = customerValue;
        query.set("customerValue", customerValue);
      }
      if (partnerValue !== "" && partnerValue !== undefined) {
        searchParams["partner"] = partner;
        query.set("partner", partner);
        searchParams["partnerValue"] = partnerValue;
        query.set("partnerValue", partnerValue);
      }
      if (appReferenceValue !== "" && appReferenceValue !== undefined) {
        searchParams["appRef"] = appRef;
        query.set("appRef", appRef);
        searchParams["appReferenceValue"] = appReferenceValue;
        query.set("appReferenceValue", appReferenceValue);
      }
      if (appStatusValue !== "" && appStatusValue !== undefined) {
        searchParams["appointmentStatus"] = appointmentStatus;
        query.set("appointmentStatus", appointmentStatus);
        searchParams["appStatusValue"] = appStatusValue;
        query.set("appStatusValue", appStatusValue);
        if (
          appStatusValue !== "app" &&
          appointmentAdvancedValue !== "" &&
          appointmentAdvancedValue !== undefined
        ) {
          searchParams["appointmentAdvancedValue"] = appointmentAdvancedValue;
          query.set("appointmentAdvancedValue", appointmentAdvancedValue);
        }
      }
      if (payStatusValue !== "" && payStatusValue !== undefined) {
        searchParams["paymentStatus"] = paymentStatus;
        query.set("paymentStatus", paymentStatus);
        searchParams["payStatusValue"] = payStatusValue;
        query.set("payStatusValue", payStatusValue);
      }
      if (payMethodValue !== "" && payMethodValue !== undefined) {
        searchParams["paymentMethod"] = paymentMethod;
        query.set("paymentMethod", paymentMethod);
        searchParams["payMethodValue"] = payMethodValue;
        query.set("payMethodValue", payMethodValue);
        if (
          payMethodValue !== "all" &&
          paymentAdvancedValue !== undefined &&
          paymentAdvancedValue !== ""
        ) {
          searchParams["paymentAdvancedValue"] = paymentAdvancedValue;
          query.set("paymentAdvancedValue", paymentAdvancedValue);
        }
      }
      if (genderValue !== "" && genderValue !== undefined) {
        searchParams["gender"] = gender;
        query.set("gender", gender);
        searchParams["genderValue"] = genderValue;
        query.set("genderValue", genderValue);
        if (
          genderValue !== "any" &&
          genderAdvancedValue !== undefined &&
          genderAdvancedValue !== ""
        ) {
          searchParams["genderAdvancedValue"] = genderAdvancedValue;
          query.set("genderAdvancedValue", genderAdvancedValue);
        }
      }

      //advanced params

      query.set("offset", offset);
      query.set("sortBy", sortBy);
      query.set("sortOrder", sortOrder);
      query.set("list", id);
      query.set("pd", pd);
      query.set("pc", pc);
      query.set("page", isClicked ? 1 : page);
      query.set("showFilter", showFilter);
      if (createdAtStartValueExist) {
        query.set("quick", quickVal);
      } else {
        query.set("quick", "");
      }

      query.set("showAdvanceFilter", showAdvanceFilter);

      // console.log(query.toString())
      setSearchParams(searchParams);
      if (download)
        return query.toString()
          ? downloadUrl + `?` + query.toString()
          : downloadUrl;
      return query.toString() ? listUrl + `?` + query.toString() : secondaryUrl;
    };

    const handleAppointmentFilter = async (isClicked = false) => {
      await switchStatement(listName, true, isClicked);
    };
    const handleResetFilter = async () => {
      //setting values to null
      if (document.getElementById("appReference")?.value)
        document.getElementById("appReference").value = "";
      if (document.getElementById("appointment_status")?.value)
        document.getElementById("appointment_status").value = "app";
      if (document.getElementById("duty_status")?.value)
        document.getElementById("duty_status").value = "all";
      if (document.getElementById("payment_status")?.value)
        document.getElementById("payment_status").value = "pay";
      if (document.getElementById("payment_mode")?.value)
        document.getElementById("payment_mode").value = "all";
      if (document.getElementById("frequency_value")?.value)
        document.getElementById("frequency_value").value = "all";
      if (document.getElementById("country_value")?.value)
        document.getElementById("country_value").value = "all";

      if (document.getElementById("countryAdvancedValue")?.value)
        document.getElementById("countryAdvancedValue").value = "";
      if (document.getElementById("frequencyAdvancedValue")?.value)
        document.getElementById("frequencyAdvancedValue").value = "";
      if (document.getElementById("paymentAdvancedValue")?.value)
        document.getElementById("paymentAdvancedValue").value = "";
      if (document.getElementById("appointmentAdvancedValue")?.value)
        document.getElementById("appointmentAdvancedValue").value = "";
      if (document.getElementById("dutyAdvancedValue")?.value)
        document.getElementById("dutyAdvancedValue").value = "";
      if (document.getElementById("createdAtAdvancedValue")?.value)
        document.getElementById("createdAtAdvancedValue").value = "";
      if (document.getElementById("startDateAdvancedValue")?.value)
        document.getElementById("startDateAdvancedValue").value = "";
      if (document.getElementById("dobAdvancedValue")?.value)
        document.getElementById("dobAdvancedValue").value = "";

      //setting false boolean values
      if (customer) setCustomer(false);
      if (gender) setGender(false);
      if (cleaner) setCleaner(false);
      if (driver) setDriver(false);
      if (partner) setPartner(false);
      if (frequency) setFrequency(false);
      if (country) setCountry(false);
      if (paymentMethod) setPaymentMethod(false);
      if (startDate) setStartDate(false);
      if (createdAt) setCreatedAt(false);
      if (paymentStatus) setPaymentStatus(false);
      if (appointmentStatus) setAppointmentStatus(false);
      if (appRef) setAppRef(false);
      if (dutyStatus) setDutyStatus(false);
      if (rhMember) setRhMember(false);
      if (dob) setDob(false);

      await switchStatement(listName, false, false, true);
    };

    const [showAdvanceFilter, setShowAdvanceFilter] = useState(
      searchQuery?.get("showAdvanceFilter") === "true" || false
    );
    const onClickAdvanceFilter = () => {
      setShowAdvanceFilter(!showAdvanceFilter);
      let sp = Object.fromEntries([...searchQuery]);
      sp["showAdvanceFilter"] = !showAdvanceFilter;
      setSearchQuery(sp);
    };

    let middlePages;

    middlePages = (
      <div>
        <div className="FilterList">
          <ul>
            {appRef !== undefined && (
              <li>
                <input
                  type="checkbox"
                  checked={appRef}
                  onChange={(e) => setAppRef(e.target.checked)}
                />
                {listName === "appointment" || listName === "partnerPayment"
                  ? "Reference"
                  : listName === "review"
                  ? "Reference/Review"
                  : listName === "reportName"
                  ? "Name"
                  : "Name/email"}
              </li>
            )}
            {gender !== undefined && (
              <li>
                <input
                  type="checkbox"
                  checked={gender}
                  onChange={(e) => setGender(e.target.checked)}
                />
                Gender
              </li>
            )}
            {rhMember !== undefined && (
              <li>
                <input
                  type="checkbox"
                  checked={rhMember}
                  onChange={(e) => setRhMember(e.target.checked)}
                />
                Rh Club Member
              </li>
            )}
            {dob !== undefined && (
              <li>
                <input
                  type="checkbox"
                  checked={dob}
                  onChange={(e) => setDob(e.target.checked)}
                />
                DOB
              </li>
            )}
            {createdAt !== undefined && (
              <li>
                <input
                  type="checkbox"
                  checked={createdAt}
                  onChange={(e) => setCreatedAt(e.target.checked)}
                />
                Created At
              </li>
            )}
            {frequency !== undefined && (
              <li>
                <input
                  type="checkbox"
                  checked={frequency}
                  onChange={(e) => setFrequency(e.target.checked)}
                />
                Frequency
              </li>
            )}
            {customer !== undefined && (
              <li>
                <input
                  type="checkbox"
                  checked={customer}
                  onChange={(e) => setCustomer(e.target.checked)}
                />
                Customer
              </li>
            )}
            {cleaner !== undefined && (
              <li>
                <input
                  type="checkbox"
                  checked={cleaner}
                  onChange={(e) => setCleaner(e.target.checked)}
                />
                Pro
              </li>
            )}
            {driver !== undefined && (
              <li>
                <input
                  type="checkbox"
                  checked={driver}
                  onChange={(e) => setDriver(e.target.checked)}
                />
                Driver
              </li>
            )}
            {partner !== undefined && (
              <li>
                <input
                  type="checkbox"
                  checked={partner}
                  onChange={(e) => setPartner(e.target.checked)}
                />
                Partner
              </li>
            )}
            {country !== undefined && (
              <li>
                <input
                  type="checkbox"
                  checked={country}
                  onChange={(e) => setCountry(e.target.checked)}
                />
                Country
              </li>
            )}
            {startDate !== undefined && (
              <li>
                <input
                  type="checkbox"
                  checked={startDate}
                  onChange={(e) => setStartDate(e.target.checked)}
                />
                Start Date
              </li>
            )}

            {paymentMethod !== undefined && (
              <li>
                <input
                  type="checkbox"
                  checked={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.checked)}
                />
                Payment Method
              </li>
            )}
            {paymentStatus !== undefined && (
              <li>
                <input
                  type="checkbox"
                  checked={paymentStatus}
                  onChange={(e) => setPaymentStatus(e.target.checked)}
                />
                Payment Status
              </li>
            )}
            {appointmentStatus !== undefined && (
              <li>
                <input
                  type="checkbox"
                  checked={appointmentStatus}
                  onChange={(e) => setAppointmentStatus(e.target.checked)}
                />
                Appointment Status
              </li>
            )}
            {dutyStatus !== undefined && (
              <li>
                <input
                  type="checkbox"
                  checked={dutyStatus}
                  onChange={(e) => setDutyStatus(e.target.checked)}
                />
                Duty Status
              </li>
            )}
          </ul>
        </div>
        <div className="card clear-both mt-5 p-5 mb-5 ">
          <div className="flex w-full justify-between flex-wrap lg:flex-nowrap	">
            <div className="FilterForm w-8/12">
              {appRef ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium ">
                    {listName === "appointment" || listName === "partnerPayment"
                      ? "Reference"
                      : listName === "review"
                      ? "Reference/Review"
                      : listName === "reportName"
                      ? "Name"
                      : "Name/email"}
                  </label>
                  <div className="flex mb-4  ">
                    <div className="w-full">
                      <div className="form-group mb-3 flex items-center">
                        {/*{showAdvanceFilter ? (*/}
                        {/*    <div className="w-1/3 mr-5">*/}
                        {/*        <select className="formControl ">*/}
                        {/*            <option>*/}
                        {/*                One Time*/}
                        {/*            </option>*/}
                        {/*            <option>*/}
                        {/*                Two Time*/}
                        {/*            </option>*/}
                        {/*        </select>*/}
                        {/*    </div>*/}
                        {/*) : null}*/}
                        <div className="w-1/3 mr-5">
                          <input
                            id="appReference"
                            type="text"
                            className="formControl "
                          />
                        </div>
                        <span
                          className="fa fa-minus text-red-900"
                          onClick={() => setAppRef(false)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {createdAt ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium">
                    Created At
                  </label>
                  <div className="flex mb-4  ">
                    {showAdvanceFilter ? (
                      <div className="w-1/3 mr-5">
                        <label className="block mb-2 invisible">111</label>
                        <select
                          className="formControl "
                          id="createdAtAdvancedValue"
                        >
                          <option value="">--</option>
                          <option value="bw">between</option>
                          <option value="nbw">not between</option>
                        </select>
                      </div>
                    ) : null}
                    <div className="w-1/3">
                      <label className="block mb-2 ">From</label>
                      <div className="form-group mb-3 flex items-center">
                        <input
                          type="date"
                          id="created_at_start"
                          className="formControl mr-5"
                        />
                      </div>
                    </div>
                    <div className="w-1/3">
                      <label className="block mb-2 ">To</label>
                      <div className="form-group mb-3 flex items-center">
                        <input
                          type="date"
                          id="created_at_end"
                          className="formControl mr-5"
                        />
                        <span
                          className="fa fa-minus text-red-900"
                          onClick={() => setCreatedAt(false)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {rhMember ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium ">
                    RH member Status
                  </label>
                  <div className="flex mb-4 items-center ">
                    <div className="w-1/3 mr-5">
                      <div className="form-group mb-3 ">
                        <select className="formControl" id="rh_member">
                          <option value="all">All Customers</option>
                          <option value={false}>Inactive</option>
                          <option value={true}>Active</option>
                        </select>
                      </div>
                    </div>
                    <span
                      className="fa fa-minus text-red-900"
                      onClick={() => setRhMember(false)}
                    />
                  </div>
                </div>
              ) : null}

              {dob ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium">DOB</label>
                  <div className="flex mb-4  ">
                    {showAdvanceFilter ? (
                      <div className="w-1/3 mr-5">
                        <label className="block mb-2 invisible">111</label>
                        <select className="formControl " id="dobAdvancedValue">
                          <option value="">--</option>
                          <option value="bw">between</option>
                          <option value="nbw">not between</option>
                        </select>
                      </div>
                    ) : null}
                    <div className="w-1/3">
                      <label className="block mb-2 ">From</label>
                      <div className="form-group mb-3 flex items-center">
                        <input
                          type="date"
                          id="dob_start"
                          className="formControl mr-5"
                        />
                      </div>
                    </div>
                    <div className="w-1/3">
                      <label className="block mb-2 ">To</label>
                      <div className="form-group mb-3 flex items-center">
                        <input
                          type="date"
                          id="dob_end"
                          className="formControl mr-5"
                        />
                        <span
                          className="fa fa-minus text-red-900"
                          onClick={() => setDob(false)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {frequency ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium ">
                    Frequency
                  </label>
                  <div className="flex mb-4 flex items-center">
                    {showAdvanceFilter ? (
                      <div className="w-1/3 mr-5">
                        <select
                          className="formControl"
                          id="frequencyAdvancedValue"
                        >
                          <option value="">--</option>
                          <option value="eq">is Equal To</option>
                          <option value="ne">is Not Equal To</option>
                        </select>
                      </div>
                    ) : null}
                    <div className="w-1/3 mr-5 ">
                      <div className="form-group  ">
                        <select
                          className="formControl"
                          name="frequency_value"
                          id="frequency_value"
                        >
                          {frequencys?.length > 0 ? (
                            frequencys.map((frequency, index) => {
                              if (index === 0) {
                                return (
                                  <>
                                    <option value="all">All Frequency</option>
                                    <option key={index} value={frequency._id}>
                                      {frequency.package_name +
                                        "( " +
                                        frequency?.countryDetails?.name +
                                        "/" +
                                        frequency.service_type +
                                        " )"}
                                    </option>
                                  </>
                                );
                              } else {
                                return (
                                  <option key={index} value={frequency._id}>
                                    {frequency.package_name +
                                      "( " +
                                      frequency?.countryDetails?.name +
                                      "/" +
                                      frequency.service_type +
                                      " )"}
                                  </option>
                                );
                              }
                            })
                          ) : (
                            <option>No Frequency found</option>
                          )}
                        </select>
                      </div>
                    </div>
                    <span
                      className="fa fa-minus text-red-900"
                      onClick={() => setFrequency(false)}
                    />
                  </div>
                </div>
              ) : null}

              {customer ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium ">
                    Customer
                  </label>
                  <div className="flex mb-4 flex items-center  ">
                    <div className=" w-1/3 mr-5 ">
                      <div className="form-group ">
                        <input
                          id="customerValue"
                          type="text"
                          className="formControl "
                        />
                      </div>
                    </div>
                    <span
                      className="fa fa-minus text-red-900"
                      onClick={() => setCustomer(false)}
                    />
                  </div>
                </div>
              ) : null}
              {cleaner ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium ">Pro</label>
                  <div className="flex mb-4 flex items-center  ">
                    <div className=" w-1/3 mr-5 ">
                      <div className="form-group ">
                        <input
                          id="cleanerValue"
                          type="text"
                          className="formControl "
                        />
                      </div>
                    </div>
                    <span
                      className="fa fa-minus text-red-900"
                      onClick={() => setCleaner(false)}
                    />
                  </div>
                </div>
              ) : null}
              {driver ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium ">
                    Driver
                  </label>
                  <div className="flex mb-4 flex items-center  ">
                    <div className=" w-1/3 mr-5 ">
                      <div className="form-group ">
                        <input
                          id="driverValue"
                          type="text"
                          className="formControl "
                        />
                      </div>
                    </div>
                    <span
                      className="fa fa-minus text-red-900"
                      onClick={() => setDriver(false)}
                    />
                  </div>
                </div>
              ) : null}
              {partner ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium ">
                    Partner
                  </label>
                  <div className="flex mb-4 flex items-center  ">
                    <div className=" w-1/3 mr-5 ">
                      <div className="form-group ">
                        <input
                          id="partnerValue"
                          type="text"
                          className="formControl "
                        />
                      </div>
                    </div>
                    <span
                      className="fa fa-minus text-red-900"
                      onClick={() => setPartner(false)}
                    />
                  </div>
                </div>
              ) : null}

              {country ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium ">
                    Country
                  </label>
                  <div className="flex mb-4  ">
                    <div className="w-full">
                      <div className="form-group mb-3 flex items-center">
                        {showAdvanceFilter ? (
                          <div className="w-1/3 mr-5">
                            <select
                              className="formControl"
                              id="countryAdvancedValue"
                            >
                              <option value="">--</option>
                              <option value="eq">is Equal To</option>
                              <option value="ne">is Not Equal To</option>
                            </select>
                          </div>
                        ) : null}
                        <div className="w-1/3 mr-5">
                          <select
                            className="formControl"
                            name="country_value"
                            id="country_value"
                          >
                            {countries?.length > 0 ? (
                              countries.map((country, index) => {
                                if (index === 0) {
                                  return (
                                    <>
                                      <option value="all">All Countries</option>
                                      <option key={index} value={country._id}>
                                        {country.name}
                                      </option>
                                    </>
                                  );
                                } else {
                                  return (
                                    <option key={index} value={country._id}>
                                      {country.name}
                                    </option>
                                  );
                                }
                              })
                            ) : (
                              <option>No Country found</option>
                            )}
                          </select>
                        </div>
                        <span
                          className="fa fa-minus text-red-900"
                          onClick={() => setCountry(false)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="w-full mb-2">
                {startDate ? (
                  <div className="mb-2">
                    <label className="block mb-2 text-lg font-medium">
                      Start Date
                    </label>
                    <div className="flex mb-5 items-center ">
                      {showAdvanceFilter ? (
                        <div className="w-1/3 mr-5">
                          <label className="block mb-2 invisible">111</label>
                          <select
                            className="formControl "
                            id="startDateAdvancedValue"
                          >
                            <option value="">--</option>
                            <option value="bw">between</option>
                            <option value="nbw">not between</option>
                          </select>
                        </div>
                      ) : null}
                      <div className="w-1/3 mr-5">
                        <label className="block mb-2 ">From</label>
                        <div className="form-group flex items-center">
                          <input
                            type="date"
                            id="start_date_value"
                            className="formControl "
                          />
                        </div>
                      </div>
                      <div className="w-1/3 ">
                        <label className="block mb-2 ">To</label>
                        <div className="form-group flex items-center">
                          <input
                            type="date"
                            id="end_date_value"
                            className="formControl mr-5"
                          />
                          <span
                            className="fa fa-minus text-red-900"
                            onClick={() => setStartDate(false)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              {gender ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium ">
                    Gender
                  </label>
                  <div className="flex mb-4  ">
                    <div className="w-full">
                      <div className="form-group mb-3 flex items-center">
                        {showAdvanceFilter ? (
                          <div className="w-1/3 mr-5">
                            <select
                              className="formControl"
                              id="genderAdvancedValue"
                            >
                              <option value="">--</option>
                              <option value="eq">is Equal To</option>
                              <option value="ne">is Not Equal To</option>
                            </select>
                          </div>
                        ) : null}
                        <div className="w-1/3 mr-5">
                          <select className="formControl" id="genderValue">
                            {/*{(listName === 'cleaner' || listName === 'driver') ? (*/}
                            {/*    // <>*/}
                            {/*    //     <option value=''>Any Gender</option>*/}
                            {/*    //     <option value={'male'}>Male</option>*/}
                            {/*    //     <option value={'female'}>Female</option>*/}
                            {/*    // </>*/}
                            {/*) : (*/}
                            <>
                              <option value="">Any Gender</option>
                              <option value={"Mr."}>Male</option>
                              <option value={"Ms."}>Female</option>
                            </>
                            {/*)}*/}
                          </select>
                        </div>
                        <span
                          className="fa fa-minus text-red-900"
                          onClick={() => setGender(false)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {paymentMethod ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium ">
                    Payment Method
                  </label>
                  <div className="flex mb-4  ">
                    <div className="w-full">
                      <div className="form-group mb-3 flex items-center">
                        {showAdvanceFilter ? (
                          <div className="w-1/3 mr-5">
                            <select
                              className="formControl"
                              id="paymentAdvancedValue"
                            >
                              <option value="">--</option>
                              <option value="eq">is Equal To</option>
                              <option value="ne">is Not Equal To</option>
                            </select>
                          </div>
                        ) : null}
                        <div className="w-1/3 mr-5">
                          <select className="formControl" id="payment_mode">
                            <option
                              value="pay"
                              selected={
                                paymentStatus === "all" ? "selected" : null
                              }
                            >
                              All Appointments
                            </option>
                            {paymentModeArray.map((value, idx) => (
                              <option key={idx} value={value["key"]}>
                                {value["pair"]}
                              </option>
                            ))}
                          </select>
                        </div>
                        <span
                          className="fa fa-minus text-red-900"
                          onClick={() => setPaymentMethod(false)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {paymentStatus ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium ">
                    Payment Status
                  </label>
                  <div className="flex mb-4 items-center ">
                    <div className="w-1/3 mr-5">
                      <div className="form-group mb-3 ">
                        <select className="formControl" id="payment_status">
                          <option value="pay">All Appointments</option>
                          <option value={2}>Paid</option>
                          <option value={1}>Unpaid</option>
                        </select>
                      </div>
                    </div>
                    <span
                      className="fa fa-minus text-red-900"
                      onClick={() => setPaymentStatus(false)}
                    />
                  </div>
                </div>
              ) : null}

              {appointmentStatus ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium ">
                    Appointment Status
                  </label>
                  <div className="flex mb-4  ">
                    <div className="w-full">
                      <div className="form-group mb-3 flex items-center">
                        {showAdvanceFilter ? (
                          <div className="w-1/3 mr-5">
                            <select
                              className="formControl"
                              id="appointmentAdvancedValue"
                            >
                              <option value=""> --</option>
                              <option value="eq"> is Equal To</option>
                              <option value="ne"> is Not Equal To</option>
                            </select>
                          </div>
                        ) : null}
                        <div className="w-1/3 mr-5">
                          <select
                            className="formControl"
                            id="appointment_status"
                          >
                            <option value="app">All Appointments</option>
                            <option value={1}>Pending</option>
                            <option value={2}>Waiting</option>
                            <option value={3}>Completed</option>
                            <option value={4}>Cancelled</option>
                            <option value={5}>On Going</option>
                            <option value={6}>Refunded</option>
                          </select>
                        </div>
                        <span
                          className="fa fa-minus text-red-900"
                          onClick={() => setAppointmentStatus(false)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {dutyStatus ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium ">
                    Duty Status
                  </label>
                  <div className="flex mb-4  ">
                    <div className="w-full">
                      <div className="form-group mb-3 flex items-center">
                        {showAdvanceFilter ? (
                          <div className="w-1/3 mr-5">
                            <select
                              className="formControl"
                              id="dutyAdvancedValue"
                            >
                              <option value=""> --</option>
                              <option value="eq"> is Equal To</option>
                              <option value="ne"> is Not Equal To</option>
                            </select>
                          </div>
                        ) : null}
                        <div className="w-1/3 mr-5">
                          <select className="formControl" id="duty_status">
                            <option value="all">All Status</option>
                            <option value={"Available"}>Available</option>
                            <option value={"Not Available"}>
                              Not Available
                            </option>
                            <option value={"On Duty"}>On Duty</option>
                          </select>
                        </div>
                        <span
                          className="fa fa-minus text-red-900"
                          onClick={() => setDutyStatus(false)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="advancedFilter w-3/12 ">
              <div className="form-group flex pt-6 justify-end flex-col">
                <button
                  onClick={() => handleAppointmentFilter(true)}
                  className="px-8 rounded py-3 hover:bg-blue-900 bg-purple-700 text-white sm:mr-2 mb-2   "
                >
                  Filter
                </button>
                <button
                  className="px-8 rounded py-3 hover:bg-blue-900 bg-purple-700 text-white sm:mr-2 mb-2 "
                  onClick={() => onClickAdvanceFilter()}
                >
                  <span className="fa fa-cogs" /> Advanced Filter
                </button>
                <button
                  onClick={() => handleResetFilter()}
                  className="px-8 rounded py-3 hover:bg-red-500 bg-red-500 text-white mb-2 "
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <>
        <div>{middlePages}</div>
      </>
    );
  }
);
export default CommonFilter;
